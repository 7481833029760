@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body, html {
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}